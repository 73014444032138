import { useEffect, useState } from "react";

const useFetchPriceData = () => {
  const [priceData, setPriceData] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.allorigins.win/get?url=https://www.coingecko.com/price_charts/279/usd/7_days.json",
          {
            method: "GET",
            headers: {
              accept: "*/*",
              "accept-language": "en-GB,en;q=0.8",
              referer: "https://www.coingecko.com/en/coins/ethereum",
              "sec-ch-ua":
                '"Chromium";v="124", "Brave";v="124", "Not-A.Brand";v="99"',
              "sec-ch-ua-mobile": "?0",
              "sec-ch-ua-model": '""',
              "sec-ch-ua-platform": "macOS",
              "sec-fetch-dest": "empty",
              "sec-fetch-mode": "cors",
              "sec-fetch-site": "same-origin",
              "sec-gpc": "1",
              "user-agent":
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
              cookie:
                "geckoTableFdvStats=false; hideAddCoinModal=true; indexCategoryCardTrend=true; _session_id=84a80518fa280bb4b0fbd4adda255deb",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const prices = JSON.parse(data.contents)["stats"];
        setPriceData(prices.map((x: number[]) => x[1]));
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data immediately, then every 10 seconds
    fetchData();
    const interval = setInterval(fetchData, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return priceData;
};

export default useFetchPriceData;
