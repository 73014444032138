import {
  Box,
  Button,
  Heading,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Link as LinkRouter } from "react-router-dom";
import "./Main.css";
import useIsMobile from "./hooks/useIsMobile";
import { CustomConnectButton } from "./CustomConnectButton";

function About() {
  return (
    <VStack width="100%" position="relative">
      <Box
        width="100%"
        display="flex"
        style={{
          zIndex: 9999,
        }}
      >
        <LinkRouter to="/">
          <Button variant="primary">HOME</Button>
        </LinkRouter>
        <Spacer />
        <CustomConnectButton />
      </Box>
      <Heading as="h1" size="4xl" marginBottom="1%">
        HAVOC
      </Heading>
      <Spacer />
      <Heading as="h1">About</Heading>
      <Text>
        This project was created by{" "}
        <Link textDecoration="underline" href="https://twitter.com/miragenesi">
          miragenesi
        </Link>
        , during dark times, for the dark times.
      </Text>
      <br />
      <Text>A way to embrace and deal with the difficult moments.</Text>
      <br />
      <Text>Looking away is never an option.</Text>
      <br />
      <Heading as="h1">Technicalities</Heading>
      <Text>
        A price oracle is used to determine the ETH/USD price change between now
        and the reference time. Each NFT will become tradeable (hence
        purchaseable) only when there is a sufficiently deep decline of the
        currency price, indicated on each NFT on the main page.
        <br />
        When the ETH price recovers, the NFT is not tradeable (hence not
        sellable) anymore, and the collector will have to wait darker times to
        either sell it or transfer it.
      </Text>
      <br />
      <Text>
        The project uses a simple internal market to allow collectors to list
        and buy tokens.
      </Text>
      <br />
      <Text>
        If you are fine with the lack of features, you can save the fees that
        you may encounter in other exchanges (5% royalties still apply).
      </Text>
      <br />
      <Text>
        The NFT Art changes based on the level of decline of ETH/USD. Data is
        sourced from Chainlink Oracles. Everything is on-chain.
      </Text>
      <br />
      <Text>
        The initial owners of the NFTs are the 4 Horsemen of Apocalypse.
      </Text>
      <br />
      <Heading as="h1">Links</Heading>
      <Text>
        Smart Contracts:{" "}
        <Link
          href="https://etherscan.io/address/0x6667AE696F1CCA32C0F3137316d5233cf8997666"
          textDecor="underline"
        >
          Havoc
        </Link>
        ,{" "}
        <Link
          href="https://etherscan.io/address/0x9684c82019dE278dF11a2473076aFf929917b4cC"
          textDecor="underline"
        >
          Market
        </Link>
        ,{" "}
        <Link
          href="https://etherscan.io/address/0x5a24e529065E982FeaCC70cDF9d967F42FD3a676"
          textDecor="underline"
        >
          ETHPrice
        </Link>
      </Text>
      <Text>
        Social:{" "}
        <Link textDecor="underline" href="https://twitter.com/miragenesi">
          X
        </Link>
        ,{" "}
        <Link href="https://discord.gg/YUceSZqP" textDecor="underline">
          Discord
        </Link>
        ,{" "}
        <Link href="https://linktr.ee/miragenesi" textDecor="underline">
          Linktree
        </Link>
        ,
        <Link href={process.env.REACT_APP_OPENSEA_URL} textDecor="underline">
          Opensea
        </Link>
      </Text>
    </VStack>
  );
}

export default About;
