import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { defineStyleConfig } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./Main";
import BackgroundChart from "./BackgroundChart";
import About from "./About";
import { useState } from "react";

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "0", // <-- border radius is same for all variants and sizes
    borderColor: "#4f4f4f",
    borderWidth: "0.5px",
  },
  variants: {
    primary: (props) => ({
      fontSize: "md",
      color: "white",
      paddingTop: "4px",
      backgroundColor: "black",
      _hover: {
        backgroundColor: "rgb(168, 46, 38)",
        color: "black",
      },
    }),
    disabled: (props) => ({
      fontSize: "md",
      backgroundColor: "black",
      paddingTop: "4px",
      color: "grey",
      _hover: {
        color: "white",
        backgroundColor: "black",
      },
    }),
  },
});

const theme = extendTheme({
  fonts: {
    heading: `"OptimusPrinceps", "Arial", "serif"`,
    body: `"Quicksand", "Arial", "serif"`,
  },
  styles: {
    global: {
      "html, body": {
        background: "black",
        textColor: "rgb(168, 46, 38)",
        textAlign: "center",
        fontWeight: "bold",
        borderWidth: "10px",
        borderColor: "black",
      },
    },
  },
  components: {
    Button,
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "about",
    element: <About />,
  },
]);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BackgroundChart />
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
