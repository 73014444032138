import { useEffect, useState } from "react";

export const useFluxPercentage = (initialValue: number, threshold: number) => {
  const [percentage, setPercentage] = useState(initialValue);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(() => {
        const upOrDown = [-1, 0, 1][Math.floor(Math.random() * 10) % 3]; // Randomly decide to go up or down
        if (initialValue === threshold + 1) {
          return Math.max(initialValue + upOrDown, initialValue);
        }
        return initialValue + upOrDown;
      });
    }, 100); // 500ms interval to update 2 times per second

    return () => clearInterval(interval);
  }, [initialValue, threshold]);

  return percentage;
};
