import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./Main.css";
import { CustomConnectButton } from "./CustomConnectButton";
import Horse from "./Horse";
import useIsMobile from "./hooks/useIsMobile";
import useCurrentPrice from "./hooks/useCurrentPrice";

function Main() {
  const isMobile = useIsMobile();
  const price = useCurrentPrice();

  return (
    <VStack width="100%" height="95vh" position="relative">
      <Box
        width="100%"
        display="flex"
        style={{
          zIndex: 9999,
        }}
      >
        <Link to="about">
          <Button variant="primary">ABOUT</Button>
        </Link>
        <Spacer />
        <CustomConnectButton />
      </Box>
      <Heading as="h1" size="4xl" marginBottom="1%">
        HAVOC
      </Heading>
      <Text>ETH/USD {price || "..."}</Text>
      <Spacer />
      <Text className="content">
        These tokens are tradable only during dire market conditions, when ETH
        is down. <br />
        The art anxiogenicity reflects the level of decline.
      </Text>
      <Spacer />
      <Flex direction={isMobile ? "column" : "row"} width="80%">
        <Horse minCrash={-35} tokenId={4} name="havoc" period="yearly" />
        <Spacer />
        <Horse minCrash={-25} tokenId={3} name="despair" period="monthly" />
        <Spacer />
        <Horse minCrash={-15} tokenId={2} name="crisis" period="weekly" />
        <Spacer />
        <Horse minCrash={-5} tokenId={1} name="panic" period="daily" />
      </Flex>
      <Spacer />
      <Text className="content">
        The project explores difficult emotional states, challenging the
        collectors to take action during moments of deep distress.
      </Text>
      <Spacer />
    </VStack>
  );
}

export default Main;
