import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useFetchPriceData from "./hooks/usePrice";

Chart.register(...registerables);

const BackgroundChart: React.FC = () => {
  const dataPoints = useFetchPriceData();

  const chartData = {
    labels: dataPoints.map((_, index) => index),
    datasets: [
      {
        label: "Price",
        data: dataPoints,
        borderColor: "rgb(168, 46, 38)",
        backgroundColor: "rgb(0, 0, 0)",
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "20vh",
        zIndex: 1,
        backgroundColor: "transparent",
      }}
    >
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default BackgroundChart;
