import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { mainnet, sepolia, localhost } from "wagmi/chains";
import { http, createConfig, WagmiProvider } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const CHAINS = {
  "1": [mainnet, mainnet.id],
  "11155111": [sepolia, sepolia.id],
  "1337": [localhost, localhost.id],
} as const;

const chainId = process.env.REACT_APP_CHAIN_ID! as keyof typeof CHAINS;

const currentChain = CHAINS[chainId];

const config = getDefaultConfig({
  appName: "HAVOC",
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(process.env.REACT_APP_TRANSPORT),
  },
});

const wagmiConfig = createConfig(config);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider theme="auto">
          <App />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
