import { useReadContract } from "wagmi";
import { abi, address } from "./havoc";
import { useEffect, useState } from "react";

export default function useToken(tokenId: number) {
  const [svg, setSVG] = useState("");

  const result = useReadContract({
    abi,
    address: address,
    functionName: "tokenURI",
    args: [tokenId],
    query: {
      refetchInterval: 1200,
    },
  });

  useEffect(() => {
    if (result.data) {
      const data = result.data as string;
      const decoded: any = atob(
        data.replace("data:application/json;base64,", "")
      );

      const image = atob(
        JSON.parse(decoded)["image"].replace("data:image/svg+xml;base64,", "")
      );

      setSVG(image);
    }
  }, [result]);

  return svg;
}
