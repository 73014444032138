import React from "react";
import { useFluxPercentage } from "./hooks/useFluxPercentage";

interface PercentageDisplayProps {
  initialPercentage: number;
  threshold: number;
}

function Change(props: PercentageDisplayProps) {
  const percentage = useFluxPercentage(
    props.initialPercentage,
    props.threshold
  );

  return <span>{percentage}%</span>;
}

export default Change;
