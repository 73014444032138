import { useEffect, useState } from "react";

const useCurrentPrice = () => {
  const [price, setPrice] = useState<number>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD",
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPrice(Math.floor(data["USD"]));
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data immediately, then every 10 seconds
    fetchData();
    const interval = setInterval(fetchData, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return price;
};

export default useCurrentPrice;
