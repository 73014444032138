import {
  Box,
  Button,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import "./Main.css";
import {
  type BaseError,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { abi, address } from "./hooks/market";
import { useEffect, useState } from "react";
import { formatEther, parseEther } from "viem";

interface ListProps {
  tokenId: number;
  listingPrice: bigint;
  setUpdate: (b: boolean) => void;
}

function List(props: ListProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState("");
  const handleChange = (event: any) => setValue(event.target.value);

  const {
    data: hash,
    isPending,
    isError,
    error,
    writeContract,
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError: failed,
  } = useWaitForTransactionReceipt({
    hash,
    confirmations: 4,
  });

  useEffect(() => {
    props.setUpdate(isConfirmed);
    onClose();
  }, [isConfirmed]);

  function list() {
    writeContract({
      abi,
      address: address,
      functionName: "list",
      args: [BigInt(props.tokenId), parseEther(value)],
    });
  }

  function isLoading() {
    return isConfirming || isPending;
  }

  function isPriceValid() {
    return !isNaN(parseFloat(value)) && isFinite(Number(value));
  }

  return (
    <Box>
      {error && (
        <div>Error: {(error as BaseError).shortMessage || error.message}</div>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          backgroundColor="black"
          borderColor="rgb(168, 46, 38)"
          borderWidth="1px"
        >
          <ModalHeader>Listing</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            By listing the token through this app, you won't pay the market fees
            (e.g. 2.5% on Opensea), so... congrats!
            <br />
            <br />
            You are still able to list the token through other exchanges though
            if you like.
            <br />
            <br />
            The royalties for the creator are set to 5%.
            <br />
            <br />
            <Input
              placeholder="Your Price"
              onChange={handleChange}
              isInvalid={!isPriceValid()}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
            <Button
              variant="primary"
              isDisabled={!isPriceValid()}
              onClick={list}
              isLoading={isLoading()}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {props.listingPrice === BigInt(0) && (
        <Button
          isLoading={isLoading()}
          isDisabled={isLoading()}
          variant="primary"
          onClick={onOpen}
        >
          LIST
        </Button>
      )}

      {props.listingPrice > BigInt(0) && (
        <div>
          <Button
            isLoading={isLoading()}
            isDisabled={isLoading()}
            variant="primary"
            onClick={onOpen}
          >
            ReLIST
          </Button>
          <Text fontSize="10px">
            (currently at {formatEther(props.listingPrice)} ETH)
          </Text>
        </div>
      )}
    </Box>
  );
}

export default List;
