import { Text, Tooltip, VStack } from "@chakra-ui/react";
import "./Main.css";
import useToken from "./hooks/useToken";
import useCrash from "./hooks/useCrash";
import Change from "./Change";
import Buy from "./Buy";
import { useAccount, useEnsName } from "wagmi";
import useOwner from "./hooks/useOwner";
import List from "./List";
import useListing from "./hooks/useListing";
import { useEffect, useState } from "react";
import useIsMobile from "./hooks/useIsMobile";

interface HorseProps {
  minCrash: number;
  tokenId: number;
  name: string;
  period: string;
}

const HORSES = {
  "0xadb51e856ecc5c3e7415af55aa50dca642187512": "zêlos.eth",
  "0x048d7b47e845af12679abe4860484c71d4182bf2": "fames.eth",
  "0xfad592ca29774d5cc15edd6b7d719728f96925d7": "morus.eth",
  "0x9440dad49883e2285b20361cbc95fbecd24476b2": "árēs.eth",
};

function Horse(props: HorseProps) {
  const isMobile = useIsMobile();
  const { address } = useAccount();
  const owner = useOwner(props.tokenId);
  const token = useToken(props.tokenId);
  const crash = useCrash(props.tokenId);
  const ensName = useEnsName({
    address: owner as `0x{string}`,
  });

  const [update, setUpdate] = useState(false);
  const listingPrice = useListing(props.tokenId, update, setUpdate);
  const [displayedOwner, setDisplayedOwner] = useState<string>(
    shortenAddress(owner)
  );

  const canBuy = crash <= props.minCrash;

  function shortenAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    return `${address.substring(0, 5)}..${address.substring(39, 42)}`;
  }

  useEffect(() => {
    if (owner) {
      if (Object.keys(HORSES).includes(owner.toLowerCase())) {
        setDisplayedOwner(HORSES[owner.toLowerCase() as keyof typeof HORSES]);
      } else if (ensName && ensName.data !== null) {
        setDisplayedOwner(ensName.data as string);
      } else {
        setDisplayedOwner(shortenAddress(owner));
      }
    }
  }, [owner, ensName]);

  return (
    <VStack
      maxWidth={isMobile ? "100%" : "18%"}
      marginBottom={isMobile ? "20px" : "0"}
    >
      <Text className="title">{props.name}</Text>
      <Text className="description" marginTop="-10px">
        active at {props.minCrash}% ({props.period})
      </Text>
      <Text className="description" marginTop="-10px">
        {props.period} change:{" "}
        <Change initialPercentage={crash} threshold={props.minCrash} />
      </Text>
      <div
        style={{
          borderColor: "rgba(168, 47, 38, 0.666)",
          borderWidth: "1px",
        }}
        dangerouslySetInnerHTML={{ __html: token }}
      />
      <Text className="description">{displayedOwner}</Text>
      {owner === address && (
        <List
          tokenId={props.tokenId}
          listingPrice={listingPrice}
          setUpdate={setUpdate}
        />
      )}
      {owner !== address && (
        <Buy
          tokenId={props.tokenId}
          canBuy={canBuy}
          listingPrice={listingPrice}
          setUpdate={setUpdate}
        />
      )}
    </VStack>
  );
}

export default Horse;
