import { useReadContract } from "wagmi";
import { abi, address } from "./havoc";

export default function useCrash(tokenId: number) {
  const result = useReadContract({
    abi,
    address: address,
    functionName: "crash",
    args: [tokenId],
    query: {
      refetchInterval: 1200,
    },
  });

  return Number(
    (result.data !== undefined
      ? (result.data as bigint) + BigInt(50)
      : BigInt(0)) / BigInt(10)
  );
}
